<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Newsletter </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Campagne</b-breadcrumb-item>
          </b-breadcrumb>
        </div>

        <div>
          <b-button
            variant="primary"
            @click="$router.push('/newsletter/campaign/create')"
          >
            Crea Nuova Campagna
          </b-button>
        </div>
      </template>

      <div class="mt-2">
        <div class="d-flex justify-content-between">
          <b-button-group>
            <b-button
              variant="primary-light"
              @click="getCampaignList('to_schedule')"
              :disabled="status_filter == 'to_schedule'"
            >
              Da pianificare
            </b-button>
            <b-button
              variant="primary-light"
              @click="getCampaignList('scheduled')"
              :disabled="status_filter == 'scheduled'"
            >
              Pianificate
            </b-button>
            <b-button
              variant="primary-light"
              @click="getCampaignList('completed')"
              :disabled="status_filter == 'completed'"
            >
              Invii completati
            </b-button>
          </b-button-group>
        </div>

        <b-table
          class="mt-2"
          :fields="fields"
          :items="campaigns"
          :busy="loading"
          responsive
          show-empty
        >
           <template #empty>
            <h5 class="text-center text-primary-light py-2">Non ci sono campagne.</h5>
          </template>
          <template #table-busy>
            <div class="text-center text-primary-light my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Caricamento campagne...</strong>
            </div>
          </template>
          <template #cell(id)="data">
            {{ data.item.id_campaign }}
          </template>
          <template #cell(data_creazione)="data">
            {{ data.item.creation_date }}
          </template>
          <template #cell(creato_da)="data">
            {{ data.item.created_by_alias }}
          </template>
          <template #cell(nome_campagna)="data">
            {{ data.item.campaign_name }}
          </template>
          <template #cell(oggetto)="data">
            {{ data.item.campaign_subject }}
          </template>
          <template #cell(mittente)="data">
            {{ data.item.campaign_sender_id_alias }}
          </template>
          <template #cell(destinatari)="data">
            {{ data.item.campaign_recipient_list_alias }}
          </template>
          <template #cell(opzioni)="data">
            <span class="text-nowrap">
            <feather-icon class="cursor-pointer mr-2" icon="EyeIcon" size="20" v-b-tooltip.hover.top.v-primary-light="'Dettagli'"/>
            <feather-icon class="cursor-pointer text-danger" icon="Trash2Icon" size="20" @click="deleteCampaign(data.item.id_campaign)" v-b-tooltip.hover.top.v-danger="'Elimina'"/>
            </span>
          </template>
        </b-table>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BButtonGroup,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BTable,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";

import { Requests } from "@/api";

export default {
  components: {
    BButtonGroup,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BTable,
    BSpinner,
  },

  directives: {
    'b-tooltip' : VBTooltip,
  },

  data: () => ({
    fields: [
      {
        key: "id",
        sortable: true,
      },
      {
        key: "data_creazione",
        sortable: true,
      },
      {
        key: "creato_da",
        sortable: true,
      },
      {
        key: "nome_campagna",
        sortable: true,
      },
      {
        key: "oggetto",
        sortable: true,
      },
      {
        key: "mittente",
        sortable: true,
      },
      {
        key: "destinatari",
        sortable: true,
      },
      {
        key: "opzioni",
        sortable: false,
      },
    ],

    campaigns: [],

    //Helpers

    loading: false,
    status_filter: 'to_schedule',
  }),

  created() {
    this.getCampaignList("to_schedule");
  },

  methods: {
    async getCampaignList(filter) {
      this.loading = true;

      this.status_filter = filter;

      let request_data = new FormData();

      request_data.append("filter", filter);

      try {
        const response = await Requests.getCampaignList(request_data);
        this.campaigns = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    deleteCampaign(id) {
      this.$swal.fire({
        title: "Elimina Campagna",
        text: "L'azione è irreversibile. Vuoi procedere?",
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        customClass: {
            title: "text-primary-light",
            confirmButton: 'btn btn-danger mr-2',
            cancelButton: 'btn btn-secondary'
        }
      })
    },

  },
};
</script>
